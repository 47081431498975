import React, { useState } from "react";
import { image } from "../../common/Theme";
import { RadioButton } from "../../component/Radio";
import FlagDropdown from "../../component/FlagDropdown";
import { loginFields } from "./utils";
import Validation from "../Validation/validation";
import { showError } from "../Validation";
import { useDispatch } from "react-redux";
import { logout, setUser } from "./userSlice";
import { useNavigate, NavLink } from "react-router-dom";
import { loginWithCredential } from "./api";
import Apiconnection from "../../utils/Apiconnection";
import { ToastContainer, toast } from "react-toastify";
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { Modal, Button } from '@mui/material';


const Login = () => {
  const [data, setData] = useState({ fName: '', lName: '', email: '', phone: '' });
  const [data1, setData1] = useState({ username: '', password: '' });
  const [error, setError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [selectedOption, setSelectedOption] = useState('option1');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCustomerExists, setIsCustomerExists] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log(data);

  const handleRadioChange = (option) => {
    setSelectedOption(option)
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  }

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setData1((prev) => ({ ...prev, [name]: value }));
  }

  const handleCustomerSubmit = async () => {
    try {
      const isError = Validation(data1, loginFields);

      if (Object.keys(isError).length > 0) return setError(isError);

      const payload = {
        "email": data1?.username,
        "dialcode": "+1",
        "password": data1?.password,
        "deviceType": 2,
        "platform": "CustomerPanel"
      };

      const response = await Apiconnection.post('login', payload);
      const token = response?.data?.data?.user?.webLogin;
      localStorage.setItem('prime_access_token', token);
      localStorage.setItem('userInfo', JSON.stringify(response?.data?.data?.user));

      dispatch(setUser(response?.data?.data?.user));

      toast.success(response.data.message);


      const userData = response.data.data.user;
      const { firstName, lastName, phone } = userData;

      navigate('/booking-list', {
        state: {
          email: userData?.email,
          password: data.phone,
          customerId: userData.id,
          firstName: firstName,
          lastName: lastName,
          phone: phone,
          payload
        }
      });
      window.location.reload()

    } catch (error) {
      toast.error(error.response?.data?.message);
      console.log('error', error);
    }
  };

  const checkCustomerExistence = async (phone) => {
    try {
      const payload = {
        "customerMobile": phone
      };
      const response = await Apiconnection.post('customer-exist-check', payload);
      return response.data; // Return the entire response data
    } catch (error) {
      console.error('Error checking customer existence:', error);
      toast.error('Error checking customer existence.');
      return false; // Assume customer does not exist on error
    }
  };

  const handleGuestSubmit = async () => {
    try {
      const isError = Validation(data, loginFields);

      if (Object.keys(isError).length > 0) return setError(isError);
      const customerResponse = await checkCustomerExistence(data.phone);

      // Check if the customer exists  message
      if (customerResponse.status && customerResponse.data.role.roleName === "Guest") {
        setIsCustomerExists(true); // Open the modal if customer exists
      } else {

        const payload = {
          "firstname": data?.fName,
          "lastname": data?.lName,
          "email": data?.email,
          "dialcode": "+1",
          "password": data?.phone,
        };

        localStorage.setItem('Booking_as', 'guest');

        navigate('/booking-create', { state: { payload } });
        console.log(payload);

      }

    } catch (error) {
      console.log('error', error);
    }

  };

  const handleContinueAsExistingCustomer = () => {
    setIsCustomerExists(false);
    // Create the payload to pass to the booking-create
    const payload = {
      "firstname": data?.fName,
      "lastname": data?.lName,
      "email": data?.email,
      "dialcode": "+1",
      "password": data?.phone,
    };

    navigate('/booking-create', { state: { payload } });
  }

  const handleCloseModal = () => {
    setIsCustomerExists(false);
    // setSelectedOption('option2');
  };

  const handleRegistration = () => {
    navigate('/customer-registration');
  }

  const handleSubmit = () => {
    if (selectedOption === "option2") {
      handleCustomerSubmit();
    } else {
      handleGuestSubmit();
    }
  }

  const handleLogout = () => {
    dispatch(logout());
  }

  return (
    <>
      <div className="login-sec">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="login-frm">
              <div className="login-top">
                <img src={image.logo} alt="" />
                <div className="choose-type">
                  <div className="guest-login">
                    <RadioButton onClick={handleRadioChange} />
                  </div>
                </div>
              </div>

              {selectedOption === "option1" && (
                <div className="login-form-sec">
                  <form action="">
                    <div className="container">
                      <div className="row">

                        <div className="col-md-6">
                          <div className="form-group mb-4">
                            <span style={{ marginTop: '5px' }}><img src={image.user} alt="" /></span>
                            <input className="form-control" type="text" name="fName"
                              placeholder="Your First Name" onChange={handleChange} />
                          </div>
                          {showError(error?.fName)}
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-4">
                            <span style={{ marginTop: '5px' }}><img src={image.user} alt="" /></span>
                            <input className="form-control" type="text" name="lName"
                              placeholder="Your Last Name" onChange={handleChange} />
                          </div>
                          {showError(error?.lName)}
                        </div>

                        <div className="col-md-12">
                          <div className="form-group mb-4">
                            <span><img src={image.mail} alt="" /></span>
                            <input className="form-control" type="email" name="email"
                              placeholder="Your Email ID" onChange={handleChange} />
                          </div>
                          {showError(error?.email)}
                        </div>
                        <div className="col-md-12">
                          <div className="form-group mb-4">
                            <FlagDropdown />
                            <input className="form-control" type="text" placeholder="Your Mobile Number (XXX-XXX-XXXX)" name="phone"
                              onChange={handleChange} maxLength={10}
                              onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }} />
                          </div>
                          {showError(error?.phone)}
                        </div>
                        <div className="col-12">
                          <div className="form-group mb-4">
                            <button className="mb-3 btn btn_primary" type="button" onClick={handleSubmit}>CONTINUE </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <a className="create-btn" type="button" onClick={handleRegistration}>Create New Account</a>
                  {/*  <button type="button" className="create-btn" onClick={handleLogout}>Logout</button>  */}
                </div>
              )}

              {selectedOption === "option2" && (
                <div className="login-form-sec">
                  <form action="">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group mb-4">
                            <span><img src={image.mail} alt="" /></span>
                            <input className="form-control" type="email" name="username"
                              placeholder="Your Email ID" onChange={handleChange1} />
                          </div>
                          {showError(error?.username)}
                        </div>

                        <div className="col-md-12">
                          <div className="form-group mb-4">
                            <span><img src={image.lock} alt="" /></span>
                            <input className="form-control" type={showPassword ? "text" : "password"} name="password"
                              placeholder="Password" onChange={handleChange1} />
                            <span className="eye-icon" style={{ right: '40px', top: '30%', left: 'unset' }} onClick={handleTogglePasswordVisibility}>
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </span>
                          </div>
                          {showError(error?.password)}
                        </div>

                        <div className="col-12">
                          <div className="form-group mb-4">
                            <button className="mb-3 btn btn_primary" type="button" onClick={handleSubmit}>CONTINUE </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <a className="create-btn" type="button" onClick={handleRegistration}>Create New Account</a>
                  {/*  <button type="button" className="create-btn" onClick={handleLogout}>Logout</button>  */}
                </div>
              )}

            </div>
          </div>
          <div className="col-lg-7">
            <div className="right-side">
              <img src={image.bg} alt="" className="bg-img" />
              <img src={image.car} alt="" className="car-img" />
            </div>
          </div>
        </div>
      </div>


      <Modal
        open={isCustomerExists}
        onClose={handleCloseModal}
        aria-labelledby="customer-exists-modal-title"
        aria-describedby="customer-exists-modal-description"
      >
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          border: '1px solid gray',
          borderRadius: '5px',
          maxHeight: '400px',
          overflowY: 'auto',
          width: '90%', // Optional: Set a width for smaller screens
          maxWidth: '600px' // Optional: Set a max width
        }}>
          <h2 id="customer-exists-modal-title">Customer Exists</h2>
          <hr></hr>
          <p>This phone number is already associated with an existing customer account. Would you like to continue as guest?</p>
          <hr></hr>
          <div style={{ textAlign: 'right' }}>
            <Button variant="contained" color="primary" onClick={handleContinueAsExistingCustomer}>
              Continue as Guest
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleCloseModal} style={{ marginLeft: '10px' }}>
              Login as Customer
            </Button>
          </div>
        </div>
      </Modal>


    </>
  );
};

export default Login;