import { Outlet, Navigate } from 'react-router-dom';

const Privateroute = () => {

    // const token = localStorage.getItem('prime_access_token');
    // return token ? <Outlet /> : <Navigate to="/login" />;


    const token = localStorage.getItem('prime_access_token');
    const bookingAs = localStorage.getItem('Booking_as'); // Check if the user is a guest

    // Allow access if there is a token or if the user is a guest
    return token || bookingAs === 'guest' ? <Outlet /> : <Navigate to="/login" />;
}

export default Privateroute;