import React, { useState } from 'react';
import { image } from "../common/Theme";
import {
    Table, TableBody, Modal, TableCell,
} from '@mui/material';
import Apiconnection from '../utils/Apiconnection';
import { useNavigate } from 'react-router-dom';
export function Header() {


    const [openCheckInModal, setOpenCheckInModal] = useState(false);
    const [policies, setPolicies] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handleTermsConditions = async () => {
        setLoading(true);
        try {
            const response = await Apiconnection.post('get-policies');
            if (response?.data?.status) {
                const fetchedPolicies = response?.data?.data?.policies || '';
                const cleanPolicies = fetchedPolicies.replace(/<h2>Policies<\/h2>/, '');
                setPolicies(cleanPolicies);
                setOpenCheckInModal(true);
            }
        } catch (error) {
            console.error('Error fetching policies:', error);
        } finally {
            setLoading(false);
        }
    }

    const handleLogout = () => {
        localStorage.removeItem('prime_access_token');
        localStorage.removeItem('userInfo');
        navigate('/login');
        window.location.reload();
    };

    const tokenExists = !!localStorage.getItem('prime_access_token');

    const CustomerInfo = JSON.parse(localStorage.getItem('userInfo'));

    const userInfo = {
        email: CustomerInfo?.email,
        password: CustomerInfo?.password,
        customerId: CustomerInfo?.id,
        firstName: CustomerInfo?.firstName,
        lastName: CustomerInfo?.lastName,
        phone: CustomerInfo?.phone
    };

    console.log('UserInfo',userInfo?.id)

    return (

        <div className="nav-top">
            <div className="nav-logo">
                <img src={image.logo} alt="" />
            </div>
            <div className="nav-link" style={{ gap: '10px' }}>
            <p onClick={() => navigate(tokenExists ? '/booking-list' : -1, { state: userInfo })} style={{ color: 'white', cursor: 'pointer' }}> {tokenExists ? 'Booking List' : 'Go Back'}</p> |
                {/* <p onClick={() => navigate(-1)} style={{ color: 'white', cursor: 'pointer' }}>Go Back</p> | */}
                <p onClick={() => handleTermsConditions()} style={{ color: 'white', cursor: 'pointer' }} >Terms &amp; Conditions</p> |
                {tokenExists && ( 
                <p onClick={handleLogout} style={{ color: 'white', cursor: 'pointer' }}>Logout</p>
                )}
            </div>

            <Modal
                open={openCheckInModal}
                onClose={() => setOpenCheckInModal(false)}
                aria-labelledby="check-in-modal"
                aria-describedby="check-in-modal-description">
                <div className="check-in-modal" style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white',
                    padding: '20px',
                    border: '1px solid gray',
                    borderRadius: '5px',
                    maxHeight: '400px', // Set a fixed height for the modal
                    overflowY: 'auto' // Enable vertical scrolling if content overflows
                }}>
                    <div id="check-in-modal-description" dangerouslySetInnerHTML={{ __html: policies }} />
                    <hr />
                </div>
            </Modal>

        </div>

    );
}

export default Header;