import React from "react";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/js/custom.js";
import { BrowserRouter as Router, Routes, Route, HashRouter, Navigate } from "react-router-dom";
import PrivateRoute from "./component/Routes/PrivateRoute";
import BookingCreate from "./views/Booking/BookingCreate";
import BookingDetails from "./views/Booking/BookingDetails";
import Login from "./views/Login/Login";
import BookingSummary from "./views/Booking/BookingSummary.js";
import BookingList from "./views/Booking/BookingList.js";
import MakePayment from "./views/Booking/MakePayment.js";
import CustomerResgistration from "./views/Booking/CustomerResgistration";
import BookingReceipt from "./views/Booking/BookingReceipt.js";
import Privateroute from "./utils/Privateroute.js";
import Publicroute from "./utils/Publicroute.js";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

 
function App() {

  // const token = localStorage.getItem('prime_access_token');
  const stripePromise = loadStripe('pk_test_51Q5GGKCvBhAhbwykB7YUNoeg8dkZBQPXmPi6YMqQt0N5YHe3xrARSxIC8u3fCyhabQU5ZSVhkFy6YnSnQy2bMhpK00a94t2sfm'); // Replace with your actual publishable key

  return (
    <HashRouter>
            <Elements stripe={stripePromise}>
    {/* <Router> */}
      <Routes>
      <Route element={<Publicroute />}> 
      <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path='/customer-registration' element={<CustomerResgistration />} />
        </Route>
        <Route element={<Privateroute />}> 
            <Route path='/booking-create' element={<BookingCreate />} />
            <Route path='/booking-summary' element={<BookingSummary />} />
            <Route path='/booking-list' element={<BookingList />} />
            <Route path='/booking-receipt' element={<BookingReceipt />} />
            <Route path='/payment-details' element={<MakePayment />} />
            <Route path='/booking-details' element={<BookingDetails />} />
        </Route>
      </Routes>
    {/* </Router> */}
    </Elements>
    </HashRouter>
  );
}
 
export default App;