import React, { useState } from "react";
import { image } from "../../common/Theme";
import { RadioButton } from "../../component/Radio";
import FlagDropdown from "../../component/FlagDropdown";
import Validation from "../Validation/validation";
import { showError } from "../Validation";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Apiconnection from "../../utils/Apiconnection";
import { Button, Modal, Box, FormControl, TextField, TableBody, InputLabel, FormHelperText, MenuItem, Select, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from "react-toastify";



const CustomerRegistration = () => {
    const [data, setData] = useState({
        email: '',
        phone: '',
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
        dialcode: "+1",
        dob: '',
        gender: '',
        address: '',
        profileImage: null
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [otpModalOpen, setOtpModalOpen] = useState(false);
    const [otpEmail, setOtpEmail] = useState('');
    const [otpPhone, setOtpPhone] = useState('');

    const [error, setError] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChanges = (e) => {
        const { name, value } = e.target;

        // Example validation for DD/MM/YYYY format
        const datePattern = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(20[0-9]{2})$/;

        if (name === "dob") {
            if (!datePattern.test(value)) {
                setError((prev) => ({ ...prev, dob: "Invalid date format. Please use DD/MM/YYYY." }));
            } else {
                setError((prev) => ({ ...prev, dob: null })); // Clear error if valid
            }
        }

        // Update state for all fields
        setData((prev) => ({ ...prev, [name]: value }));
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const handleTogglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
    
        // Allow only digits for phone input
        if (name === 'phone') {
            const numericValue = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
            setData((prev) => ({ ...prev, [name]: numericValue }));
        } else if (name === 'profileImage') {
            setData((prev) => ({ ...prev, [name]: e.target.files[0] }));
        } else {
            setData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const validateForm = () => {
        let errors = {};
        let isValid = true;

        if (!data.firstName) {
            errors.firstName = "First Name is required.";
            toast.error("First Name is required.");
            isValid = false;
        }
        if (!data.lastName) {
            errors.lastName = "Last Name is required.";
            toast.error("Last Name is required.");
            isValid = false;
        }
        if (!data.email) {
            errors.email = "Email is required.";
            toast.error("Email is required.");
            isValid = false;
        }
        if (!data.phone) {
            errors.phone = "Phone Number is required.";
            toast.error("Phone Number is required.");
            isValid = false;
        }
        if (!data.password) {
            errors.password = "Password is required.";
            toast.error("Password is required.");
            isValid = false;
        }
        if (data.password !== data.confirmPassword) {
            errors.confirmPassword = "Passwords do not match.";
            toast.error("Passwords do not match.");
            isValid = false;
        }

        setError(errors);
        return isValid;
    };

    // const handleCustomerSubmit = async (e) => {
    //     e.preventDefault();
    //     if (!validateForm()) return;
    //     try {
    //         const formData = new FormData();
    //         formData.append('firstName', data.firstName);
    //         formData.append('lastName', data.lastName);
    //         formData.append('email', data.email);
    //         formData.append('password', data.password);
    //         formData.append('dialCode', "+1");
    //         formData.append('phone', data.phone);
    //         formData.append('dob', data.dob);
    //         formData.append('gender', 'male');
    //         formData.append('address', data.address);
    //         formData.append('profileImage', data.profileImage);

    //         const response = await Apiconnection.post('customer-register', formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //             },
    //         });
    //         console.log('Registration successful:', response);
    //         toast.success(response.data.message);
    //         setTimeout(() => {
    //             navigate('/login');
    //         }, 2000);
    //     } catch (error) {
    //         console.log('error', error);
    //         toast.error(error.response.data.message);
    //     }
    // };

    const handleCustomerSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            const formData = new FormData();
            formData.append('firstName', data.firstName);
            formData.append('lastName', data.lastName);
            formData.append('email', data.email);
            formData.append('password', data.password);
            formData.append('dialCode', "+1");
            formData.append('phone', data.phone);
            formData.append('dob', data.dob);
            formData.append('gender', 'male');
            formData.append('address', data.address);
            formData.append('profileImage', data.profileImage);

            // Call the customer-register API
            const response = await Apiconnection.post('customer-register', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success(response.data.message);
            setOtpModalOpen(true); // Open OTP modal after successful registration
        } catch (error) {
            console.log('error', error);
            toast.error(error.response.data.message);
        }
    };

    const handleOtpSubmit = async () => {
        try {
            const response = await Apiconnection.post('validate-customer-register-otp', {
                email: data.email,
                phone: data.phone,
                emailOTP: otpEmail,
                // phoneOTP: otpPhone,
                deviceType: 2
            });

            if (response.data.status) {
                toast.success("Registration successful!");
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            }
             else {
                toast.error("Invalid OTP. Please try again.");
            }
        } catch (error) {
            console.error("Error during OTP validation:", error);
            toast.error(error.response.data.message);
        } finally {
            // setOtpModalOpen(false); // Close the modal after submission
            setOtpEmail('')
        }
    };

    
    const handleCloseModal = () => {
        setOtpModalOpen(false);
        setOtpEmail('');
        setOtpPhone('');
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <>
        <ToastContainer />
            <div className="login-sec">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="login-frm" style={{ margin: '0px -30% 0px 12%', gap: '0px' }}>
                            <div className="login-top" style={{ marginBottom: '25px' }}>
                                {/* <img src={image.logo} alt="" /> */}
                                <h5>Customer Registration</h5>
                            </div>

                            <div className="login-form-sec" style={{ margin: '0px -20px -35px -15px' }}>
                                <div className="form-container" style={{ height: '365px', padding: '5px' }}>
                                    <form action="">
                                        <div className="container">
                                            <div className="row">

                                                <div className="col-md-12 d-flex mb-4">
                                                    <div className="form-group" style={{ flex: 1, marginRight: '8px' }}>
                                                        <span style={{ top: '35%' }}><img src={image.user} alt="" /></span>
                                                        <input className="form-control" style={{ marginBottom: '-15px', border: error.firstName ? '2px solid red' : '1px solid #ccc' }} type="text" name="firstName"
                                                            placeholder="First Name " onChange={handleChange} />
                                                    </div>
                                                    <div className="form-group" style={{ flex: 1 }}>
                                                        <span style={{ top: '35%' }}><img src={image.user} alt="" /></span>
                                                        <input className="form-control" style={{ marginBottom: '-15px', border: error.lastName ? '2px solid red' : '1px solid #ccc' }} type="text" name="lastName"
                                                            placeholder="Last Name " onChange={handleChange} />
                                                    </div>
                                                </div>
                                                {/* {showError(error?.firstName)}
                    {showError(error?.lastName)} */}

                                                <div className="col-md-12">
                                                    <div className="form-group mb-4">
                                                        <span><img src={image.mail} alt="" /></span>
                                                        <input className="form-control" style={{ marginBottom: '-15px', border: error.email ? '2px solid red' : '1px solid #ccc' }} type="email" name="email"
                                                            placeholder="Email ID " onChange={handleChange} />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group mb-4">
                                                        <FlagDropdown />
                                                        <input className="form-control" style={{ marginBottom: '-15px', border: error.phone ? '2px solid red' : '1px solid #ccc' }} type="text" name="phone"
                                                            placeholder="Phone Number " onChange={handleChange} maxLength={10} 
                                                            onKeyPress={(e) => {
                                                                if (!/[0-9]/.test(e.key)) {
                                                                  e.preventDefault();
                                                                }
                                                              }}
                                                             />
                                                    </div>
                                                </div>

                                                <div className="col-md-12 d-flex mb-4">
                                                    <div className="form-group" style={{ flex: 1, marginRight: '8px' }}>
                                                    <span style={{top: '30%'}} ><img src={image.lock} alt="" /></span>
                                                        <input className="form-control" style={{ marginBottom: '-15px', border: error.password ? '2px solid red' : '1px solid #ccc' }} type={showPassword ? "text" : "password"} name="password"
                                                            placeholder="Password" onChange={handleChange} />
                                                        <span className="eye-icon" style={{right: '25px',  top: '35%', left: 'unset'}} onClick={handleTogglePasswordVisibility}>

                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </span>
                                                    </div>
                                                    <div className="form-group" style={{ flex: 1 }}>
                                                    <span style={{top: '30%'}} ><img src={image.lock} alt="" /></span>
                                                        <input className="form-control" style={{ marginBottom: '-15px', border: error.confirmPassword ? '2px solid red' : '1px solid #ccc' }} type={showPassword2 ? "text" : "password"} name="confirmPassword"
                                                            placeholder="Confirm Password" onChange={handleChange} />
                                                        <span className="eye-icon" style={{right: '25px',  top: '35%', left: 'unset'}} onClick={handleTogglePasswordVisibility2}>
                                                            {showPassword2 ? <VisibilityOff /> : <Visibility />}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group mb-4">
                                                        <span><img src={image.address} alt="" /></span>
                                                        <input type="text" style={{ padding: '15px 40px 15px 75px' }} className="form-control" name="address"
                                                            placeholder="Address" onChange={handleChange} />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="button-container">
                                    <button className="mb-3 btn btn_primary" style={{ width: '22%', height: '40px', margin: '0 10px', backgroundColor: '#4CAF50', color: '#fff', border: 'none', padding: '5px 10px', fontSize: '14px', cursor: 'pointer' }}
                                        type="button" onClick={handleCustomerSubmit}>SUBMIT</button>
                                    <button className="mb-3 btn btn_secondary" style={{ width: '22%', height: '40px', margin: '0 10px', backgroundColor: '#ccc', color: '#333', border: 'none', padding: '5px 10px', fontSize: '14px', cursor: 'pointer' }}
                                        type="button" onClick={handleBack}>BACK</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="right-side">
                            <img src={image.bg} alt="" className="bg-img" />
                            <img src={image.car} alt="" className="car-img" />
                        </div>
                    </div>
                </div>
            </div>


{/* OTP Modal */}
<Modal open={otpModalOpen} onClose={handleCloseModal}>
    <div className="terms-modal" style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        padding: '20px',
        border: '1px solid gray',
        borderRadius: '5px',
        maxHeight: '400px',
        overflowY: 'auto',
        width: '300px', // Set a fixed width for the modal
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' // Optional: Add shadow for better visibility
    }}>
        <h4 style={{ marginBottom: '20px' }}>Verify OTP</h4> {/* Title for the modal */}
        
        <div style={{ marginBottom: '15px' }}>
            <label htmlFor="emailOtp" style={{ display: 'block', marginBottom: '5px' }}>Enter Email OTP</label>
            <input 
                type="text" 
                id="emailOtp" 
                value={otpEmail} 
                onChange={(e) => setOtpEmail(e.target.value)} 
                style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }} 
            />
        </div>

        {/* <div style={{ marginBottom: '20px' }}>
            <label htmlFor="phoneOtp" style={{ display: 'block', marginBottom: '5px' }}>Enter Phone OTP</label>
            <input 
                type="text" 
                id="phoneOtp" 
                value={otpPhone} 
                onChange={(e) => setOtpPhone(e.target.value)} 
                style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }} 
            />
        </div> */}

        <button 
            onClick={handleOtpSubmit}
            style={{
                width: '100%', 
                padding: '10px', 
                backgroundColor: '#4CAF50', 
                color: 'white', 
                border: 'none', 
                borderRadius: '4px', 
                cursor: 'pointer'
            }}
        >
            Verify OTP
        </button>
    </div>
</Modal>

        </>
    );
};

export default CustomerRegistration;