import React, { useEffect, useRef, useState } from "react";
import { image } from "../../common/Theme";
import { Header } from "../../component/Header";
import { useLocation, useNavigate } from "react-router-dom";
import html2pdf from 'html2pdf.js';
import Apiconnection from '../../utils/Apiconnection';
import { toast } from "react-toastify";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const BookingDetails = () => {
  const location = useLocation();
  const [bookingDetails, setBookingDetails] = useState(location.state);
  const [customerdetails, setCustomerdetails] = useState(location.state.customerdetails);
  const [policies, setPolicies] = useState('');
  const [specificPolicy, setSpecificPolicy] = useState('');
  console.log('payload', bookingDetails);
  console.log('customerdetailspayload', customerdetails);

  const navigate = useNavigate();
  const printableRef = useRef();

  useEffect(() => {
    if (location.state) {
      setBookingDetails(location.state);
      console.log('payload', bookingDetails);
      setCustomerdetails(location.state.customerdetails);
      console.log('Customer ID:', location.state.customerdetails?.customerId);

    }
  }, [location.state]);

  // Calculate total days
  const fromDate = new Date(bookingDetails?.bookingRequestDetail?.fromDate);
  const toDate = new Date(bookingDetails?.bookingRequestDetail?.toDate);
  const totalDays = Math.ceil((toDate - fromDate) / (1000 * 60 * 60 * 24));

  const fetchPolicies = async () => {
    try {
      const response = await Apiconnection.post('get-policies');
      if (response?.data?.status) {
        const fetchedPolicies = response?.data?.data?.policies || '';
        setPolicies(fetchedPolicies);
        extractSpecificPolicy(fetchedPolicies);
      } else {
        toast.error('Failed to fetch policies');
      }
    } catch (error) {
      console.error('Error fetching policies:', error);
      toast.error('Failed to fetch policies');
    }
  };

  const extractSpecificPolicy = (policiesString) => {
    // Split policies by line breaks or HTML tags to find the second point
    const policyLines = policiesString.split(/<\/?p>/).filter(line => line.trim() !== '');
    if (policyLines.length >= 2) {
      // Extract the second policy and remove any special characters and leading numbers
      const secondPolicy = policyLines[1].replace(/<[^>]+>/g, '').trim(); // Remove HTML tags
      const cleanedPolicy = secondPolicy.replace(/^\d+\.\s*/, ''); // Remove leading number and dot
      setSpecificPolicy(cleanedPolicy);
    }
  };

  useEffect(() => {
    fetchPolicies();
  }, []);

  const handlePrint = () => {
    const element = printableRef.current;
    const opt = {
      margin: 1,
      filename: 'booking_receipt.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' }
    };

    html2pdf().from(element).set(opt).save();
  };

  // Google Maps settings
  const mapContainerStyle = {
    height: "400px",
    width: "100%"
  };

  const center = {
    lat: 40.664514,
    lng: -73.822105
  };

  return (
    <div
      className="booking-main"
      style={{
        backgroundImage: `url(${image.bodybg})`,
      }}
    >
      <div className="booking-inner">
        <Header />

        <div className="booking-inner-content">
          <div className="row ">
            <div className="col-lg-4">
              <div className="inner-right ">
                <h2 className="confim-heading">
                  <img src={image.checked} alt="" /> Booking Confirmed
                </h2>

                <div className="confirm-booking" ref={printableRef}>
                  <div className="booking-id">
                    <p>Booking ID</p>
                    <div className="download-booking">
                      <h2>{bookingDetails?.bookingRequestDetail?.bookingTicketNo}</h2>
                      <div className="download-invoice">
                        {/* <a href="#">
                          <img src={image.email} alt="" />
                        </a> */}
                        <a >
                          <img src={image.download} onClick={handlePrint} alt="" />
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="drop-details">
                    <p>
                      {bookingDetails?.bookingRequestDetail?.user?.role?.roleName === "Customer"
                        ? `Customer Name`
                        : "Customer Name (Guest)"}
                    </p>
                    <h4>{bookingDetails?.bookingRequestDetail?.user?.firstName} {bookingDetails?.bookingRequestDetail?.user?.lastName}</h4>
                  </div>

                  {/* <div className="drop-details">
                    <p>Customer Name</p>
                      <h4>{bookingDetails?.bookingRequestDetail?.user?.firstName} {bookingDetails?.bookingRequestDetail?.user?.lastName}</h4>
                  </div> */}

                  <div className="drop-details">
                    <p>Drop Off Date & Time</p>
                    <h4>{bookingDetails?.bookingRequestDetail?.fromDate} | {bookingDetails?.bookingRequestDetail?.selectedCheckInTime}</h4>
                  </div>

                  <div className="pick-details">
                    <p>Pick Up Date & Time</p>
                    <h4>{bookingDetails?.bookingRequestDetail?.toDate} | {bookingDetails?.bookingRequestDetail?.selectedCheckOutTime}</h4>
                  </div>

                  <div className="vehicle-details">
                    <div className="vehicle-plat">
                      <p>License Plate No.</p>
                      <h4>{bookingDetails?.bookingRequestDetail?.plateNumber}</h4>
                    </div>
                    <div className="vehicle-type">
                      <p>Vehicle Type</p>
                      <h4>Sedan</h4>
                    </div>
                  </div>
                  <h4 className="time-count">For {totalDays} days</h4>
                  <h4 className="amount-count">Amount to be paid at location $ {bookingDetails?.invoiceCreate?.dueAmount}<span className="note">*</span></h4>

                  <div className="policies-details mt-4" style={{ textAlign: 'justify' }}>
                    <h4 style={{ fontSize: '20px' }}>Policies:</h4>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                      <li style={{ fontSize: '12px' }}>
                        Your reservation will be subject to oversized rate ($5/day, $7/day, or $10/day) if your vehicle is a small SUV, midsize or large SUV, or Truck.
                      </li>
                      <li style={{ fontSize: '12px' }}>
                        Customer is required to take pictures of their vehicles (all sides) at the location during drop-off and also agree that no damage claim can be filed without providing those pictures.
                      </li>
                      <li style={{ fontSize: '12px' }}>
                        Customer must leave the car key with the attendant and agree that failure to do so may result in towing fees from $75 to $150.
                      </li>
                      <li style={{ fontSize: '12px' }}>
                        Customer agrees that all balances must be paid in full prior to retrieval of the vehicle.
                      </li>
                      <li style={{ fontSize: '12px' }}>
                        Your reservation will be subject to parking hourly and daily rates as soon as the voucher is expired. Hourly rate is $10/hour and daily rate is $15 or $18 per day.
                      </li>
                      <li style={{ fontSize: '12px' }}>
                        This facility does NOT allow in/out privileges. You CANNOT enter & exit more than once.
                      </li>
                      <li style={{ fontSize: '12px' }}>
                        For all canceled online vouchers/reservations, customers are required to pay for one day of parking and a $10 service fee.
                      </li>
                      <li style={{ fontSize: '12px' }}>
                        This facility does not allow online reservation extensions. Additional time must be paid on-site at regular rates.
                      </li>
                    </ul>
                  </div>

                </div>


                {bookingDetails?.customerid === '' && (
                  <div>
                    <button
                      onClick={() => navigate('/customer-registration')}
                      style={{
                        padding: '10px 20px',
                        marginTop: '-10px',
                        backgroundColor: '#4CAF50',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                      }}>
                      Go to Customer Registration
                    </button>
                  </div>
                )}


              </div>
            </div>
            <div className="col-lg-8">
              <div className="confirm-car">
                <img src={image.greenbg} alt="" className="grn-bg" />
                {/* <img src={image.bigcar} alt="" className="car-rotate" /> */}
                {/* AIzaSyBrRtkwvBcSh3_uISG8CVAX2IqykHdQEP4 */}
                <LoadScript googleMapsApiKey="AIzaSyB-XfxNzyogbUA_yFrwqaWrzHcEJQLVm-Q">
                  <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={center}
                    zoom={17}
                    options={{
                      disableDefaultUI: false, // Enable default UI controls
                      zoomControl: true, // Enable zoom control
                      streetViewControl: true, // Enable street view control
                      mapTypeControl: true, // Enable map type control
                    }}
                  >
                    <Marker position={center} />
                  </GoogleMap>
                </LoadScript>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;