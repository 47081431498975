import React, { useEffect, useState } from "react";
import { image } from "../../common/Theme";
import { Header } from "../../component/Header";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { Grid, Box, TextField, Typography, Button, FormControl, InputLabel, Select, MenuItem, IconButton, ListItemIcon, ListItemText, Input, FormControlLabel, Radio, RadioGroup, FormLabel } from '@mui/material';
import { format } from 'date-fns';
// import AdapterDateFns from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import Apiconnection from '../../utils/Apiconnection';
import { toast } from "react-toastify";
import { Modal } from '@mui/material';
import moment from "moment";
import { loadStripe } from '@stripe/stripe-js';
import { PaymentElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import PaymentForm from './PaymentForm';
// import CalendarTodayIcon from '@mui/icons-material/CalendarToday';


const BookingCreate = () => {

  const location = useLocation();
  const { email, password, customerId, firstName, lastName, phone } = location.state || {};
  console.log('Customer_payload', email, password, customerId, firstName, lastName, phone);
  const payload = location.state?.payload;
  console.log('payload', payload);

  const userId = JSON.parse(localStorage.getItem('userInfo'));
  console.log('userId',userId);

  const [termsAgreed, setTermsAgreed] = useState(false);
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [policies, setPolicies] = useState('');
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [email2, setEmail2] = useState(payload?.email);
  const [fname, setfname] = useState(payload?.firstname);
  const [lname, setlname] = useState(payload?.lastname);


  const [startDate, setstartDate] = useState(null)
  const [endDate, setendDate] = useState(null)
  const [licensePlateNumber, setLicensePlateNumber] = useState("");
  const [slotId, setSlotId] = useState('');
  const [slotName, setSlotName] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  // const { customerId } = location.state || {};
  const [mobileNumber2, setMobileNumber2] = useState(payload?.password);

  const [stripePkey, setstripePkey] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [clientsecret, setclientsecret] = useState(null);
  const [stripeorderid, setstripeorderid] = useState(null);
  const [totalAmount, settotalAmount] = useState(null);
  const [totalDueAmount, settotalDueAmount] = useState(null);
  const [totalPaidAmount, settotalPaidAmount] = useState(null);
  const [paymentres, setpaymentres] = useState('');
  const [stripePromise, setStripePromise] = useState(null);
  const [options, setOptions] = useState('pi_3QQMswCvBhAhbwyk0pPWwQmI_secret_55RFUiazZCR7YLbaYV0eSeQP6');
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [paymentFormPayload, setPaymentFormPayload] = useState(payload);

  console.log('paymentFormPayload', paymentFormPayload)


  const stripe = useStripe();
  const elements = useElements();

  // for live
  const stripepromise = loadStripe('pk_live_51Q5GGKCvBhAhbwyk3DTi7ZFGZ5ZhAxSiIYNnS8bwi6oM7UOedjm4K6hy3u6HveCNJab8v7bvMmqV42L4LEKlztbY00QtDfke2L')


  // // for test 
  // const stripepromise = loadStripe('pk_test_51Q5GGKCvBhAhbwykB7YUNoeg8dkZBQPXmPi6YMqQt0N5YHe3xrARSxIC8u3fCyhabQU5ZSVhkFy6YnSnQy2bMhpK00a94t2sfm')


  const handleCheckboxChange = async (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      await fetchPolicies();
      setOpenTermsModal(true);
    } else {
      setTermsAgreed(false);
    }
  };

  const fetchPolicies = async () => {
    try {
      const response = await Apiconnection.post('get-policies');
      if (response?.data?.status) {
        const fetchedPolicies = response?.data?.data?.policies || '';
        setPolicies(fetchedPolicies);
      } else {
        toast.error('Failed to fetch policies');
      }
    } catch (error) {
      console.error('Error fetching policies:', error);
      toast.error('Failed to fetch policies');
    }
  };

  const handleAcceptTerms = () => {
    setTermsAgreed(true);
    setOpenTermsModal(false);
  };

  const handleCancel = () => {
    setOpenModal2(false);
  };

  const handleCloseModal = () => {
    setOpenTermsModal(false);
    setTermsAgreed(false);
  };


  const EndDateHandle = () => {

    handleSlotCheck();
  }



  const handleSlotCheck = async () => {
    const payload = {
      checkinDate: moment(startDate).format(('YYYY-MM-DD')),
      startTime: moment(startDate).format(('HH:mm:ss')),
      checkoutDate: moment(endDate).format(('YYYY-MM-DD')),
      endTime: moment(endDate).format(('HH:mm:ss')),
    };

    try {
      const response = await Apiconnection.post("decide-slot", payload);
      if (response.data.status) {
        setSlotId(response.data.data.bookedSlot.id);
        setSlotName(response.data.data.bookedSlot.slotName);
        setSubmitDisabled(false);
        toast.success("Slot available");
      } else {
        setSubmitDisabled(true);
        toast.error("Slot not available");
      }
    } catch (error) {
      console.error("Error checking slot availability:", error);
    }
  };

  const handleCloseModal2 = () => {
    setOpenModal2(false);
  };

  const handleOpenModal2 = async () => {
    setOpenModal2(true);

    try {

      // Prepare the payload for the pre-booking charge summary API
      const chargeSummaryPayload = {
        platform: "CustomerPanel",
        vendorId: "", // Replace with the actual vendor ID if needed
        onSiteChargeForVendor: "", // Replace with the actual charge if needed
        vehicleTypeId: "72", // Replace with the actual vehicle type ID if needed
        passengerCount: "4", // Replace with the actual passenger count if needed
        checkinDate: moment(startDate).format('YYYY-MM-DD'),
        startTime: moment(startDate).format('HH:mm:ss'),
        checkoutDate: moment(endDate).format('YYYY-MM-DD'),
        endTime: moment(endDate).format('HH:mm:ss'),
        reservationId: "" // Replace with the actual reservation ID if needed
      };

      // Call the pre-booking charge summary API
      const chargeSummaryResponse = await Apiconnection.post('pre-booking-charge-summary', chargeSummaryPayload);

      const bookingChargesSummary = chargeSummaryResponse.data.data.bookingChargesSummary;
      settotalDueAmount(bookingChargesSummary.dueAmount);
      settotalPaidAmount(bookingChargesSummary.paidAmount);


      let totalAmount;
      if (bookingChargesSummary.payableAmount < bookingChargesSummary.reservationAmount) {
        totalAmount = bookingChargesSummary.payableAmount;
      } else {
        totalAmount = bookingChargesSummary.reservationAmount;
      }


      // const totalAmount = bookingChargesSummary.reservationAmount + bookingChargesSummary.earlyCheckinAmountTotal;

      const paymentPayload = {
          amount: totalAmount.toString(),
          currency: "USD",
          firstName: customerId ? firstName : fname,
          lastName: customerId ? lastName : lname,
          userId: userId?.id
        };
      const response = await Apiconnection.post('create-payment-intent-with-stripe', paymentPayload);

      // console.log("clientSecret", response.data.data)

      const clientSecret = {
        clientSecret: response.data.data.paymentIntent
      };
      // console.log('clientSecret',clientSecret)
      setclientsecret(clientSecret);

      setstripeorderid(response.data.data.stripeOrderId);
      settotalAmount(response.data.data.amount);


      setPaymentFormPayload({
        ...payload,
        customerId: customerId,
        licensePlateNumber: licensePlateNumber, // Use the current state value
        slotId: slotId,
        fromDate: moment(startDate).format('YYYY-MM-DD'),
        selectedCheckInTime: moment(startDate).format('HH:mm:ss'),
        toDate: moment(endDate).format('YYYY-MM-DD'),
        selectedCheckOutTime: moment(endDate).format('HH:mm:ss'),
        email: customerId ? email : payload?.email,
        phone: phone,
        firstName: firstName,
        lastName: lastName,

        // Add any other necessary fields here
      });
      console.log('stripeOrderId', stripeorderid)
    } catch (error) {
      console.error('Error creating payment intent:', error);
      toast.error('Failed to create payment intent.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newErrors = {};

    if (!licensePlateNumber) {
      toast.error("Please enter a license plate number")
    } else if (!termsAgreed) {
      toast.error("Please agree to the terms and conditions")
    } else {


      if (customerId) {

        if (!stripe || !elements) {
          toast.error('Stripe.js has not loaded yet.');
          return;
        }

        const verifyPaymentPayload = {
          stripeOrderId: paymentres,
          amount: '25',
          currency: "USD"
        };

        const verifyPaymentResponse = await Apiconnection.post('verify-payment-via-stripe', verifyPaymentPayload);

      }

      // Use the same formatting as in chargeSummaryPayload
      const formattedCheckinDate = moment(startDate).format('YYYY-MM-DD');
      const formattedStartTime = moment(startDate).format('HH:mm:ss');
      const formattedCheckoutDate = moment(endDate).format('YYYY-MM-DD');
      const formattedEndTime = moment(endDate).format('HH:mm:ss');

      let payload = {
        bookingPlatform: "customerPanel",
        logInType: customerId ? "Customer" : "Guest",
        customerId: customerId ? customerId : "",
        vehicleTypeId: "72",
        dialCode: '+1',
        slotId: slotId,
        firstName: customerId ? firstName : fname,
        lastName: customerId ? lastName : lname,
        email: customerId ? email : email2,
        phone: customerId ? phone : mobileNumber2,
        plateNumber: licensePlateNumber,
        fromDate: formattedCheckinDate, // Use formatted check-in date
        selectedCheckInTime: formattedStartTime, // Use formatted start time
        toDate: formattedCheckoutDate, // Use formatted check-out date
        selectedCheckOutTime: formattedEndTime,
        passengerCount: "4",
      };

      try {
        const response = await Apiconnection.post('booking-request-create', payload);
        if (response?.data?.status) {
          console.log(response.data.data)
          const bookingRequestId = response.data.data.id;
          // Extract values from the booking response
          const bookingRequestcheckinDate = response.data.data.fromDate; // from booking-request-create response
          const bookingRequeststartTime = response.data.data.selectedCheckInTime; // from booking-request-create response
          const bookingRequestcheckoutDate = response.data.data.toDate; // from booking-request-create response
          const bookingRequestendTime = response.data.data.selectedCheckOutTime; // from booking-request-create response

          // Prepare the payload for calculate-booking-charges-before-booking API
          const calculateBookingChargesPayload = {
            bookingRequestId,
            vehicleTypeId: "72",
            passengerCount: "4",
            checkinDate: bookingRequestcheckinDate, // Use fromDate from booking response
            startTime: bookingRequeststartTime, // Use selectedCheckInTime from booking response
            checkoutDate: bookingRequestcheckoutDate, // Use toDate from booking response
            endTime: bookingRequestendTime, // Use selectedCheckOutTime from booking response
          };
          console.log('calculateBookingChargesPayload', calculateBookingChargesPayload);

          const calculateBookingChargesResponse = await Apiconnection.post('calculate-booking-charges-before-booking', calculateBookingChargesPayload);
          console.log('calculateBookingChargesPayload', calculateBookingChargesPayload);

          if (calculateBookingChargesResponse?.data?.status) {
            toast.success('Booked Successfully!');
            const bookingDetails = {
              invoiceCreate: calculateBookingChargesResponse.data.data?.invoiceCreate,
              bookingRequestDetail: calculateBookingChargesResponse?.data?.data?.bookingRequestDetail,
              customerid: payload?.customerId
            };
            navigate('/booking-details', { state: bookingDetails });
            console.log("bookingDetails", bookingDetails)
          } else {
            toast.error('Failed to calculate booking charges!');
          }
        } else {
          toast.error('Failed to create booking!');
        }
      } catch (error) {
        console.error('Error adding vehicle data:', error);
        toast.error('Failed to create booking!');
      }
    }
  };


  return (
    <>
      <div
        className="booking-main"
        style={{
          backgroundImage: `url(${image.bodybg})`,
        }}
      >
        <div className="booking-inner">
          <Header />

          <div className="booking-inner-content">
            <div className="row ">
              <div className="col-lg-6">
                <div className="inner-right ">
                  <h2>Book Your Parking</h2>
                  <div className="login-form-sec">

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="custom-form-group mb-4">

                          <span className="checkin-calender">
                            <img src={image.calender} alt="" />
                          </span>

                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setstartDate(date)}
                            minDate={new Date()}
                            peekNextMonth
                            placeholderText="Select Start Time"
                            className="form-control input-style"
                            showTimeSelect
                            timeFormat="h:mm aa"
                            timeIntervals={5}
                            timeCaption="time"
                            dateFormat="dd-MM-yyyy h:mm aa"
                          />


                        </div>
                        <div className="custom-form-group mb-4">
                          <span className="checkin-calender">
                            <img src={image.calender} alt="" />
                          </span>

                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setendDate(date)}
                            onCalendarClose={EndDateHandle}
                            minDate={startDate}
                            peekNextMonth
                            placeholderText="Select End Time"
                            className="form-control input-style"
                            showTimeSelect
                            timeFormat="h:mm aa"
                            timeIntervals={5}
                            timeCaption="time"
                            dateFormat="dd-MM-yyyy h:mm aa"
                            disabled={startDate !== null ? false : true}
                          />


                        </div>
                        <div className="custom-form-group mb-4">
                          <span className="checkin-calender">
                            <img src={image.vehicle} alt="" />
                          </span>

                          <input
                            type="text"
                            className="form-control"
                            maxLength={10}
                            placeholder="Enter Plate Number"
                            value={licensePlateNumber}
                            onChange={(e) =>
                              setLicensePlateNumber(e.target.value)
                            }
                          />


                        </div>

                      </div>

                      <div className="col-lg-12 mb-3">
                        <div class="guest-login bk-log rdo">
                          <input
                            type="checkbox"
                            id="agreed"
                            name="terms"
                            value="agreed"
                            checked={termsAgreed}
                            onChange={handleCheckboxChange}
                          />
                          <label for="agreed">
                            I Agree With The Terms & Conditions
                          </label>
                        </div>
                        {errors.terms && (
                          <div className="error">{errors.terms}</div>
                        )}
                      </div>

                      <div className="form-group col-lg-12 ">
                        <button
                          onClick={handleOpenModal2}
                          className="mb-3 btn btn_primary"
                          type="button"
                          disabled={!termsAgreed}
                          title={!termsAgreed ? "Please accept the T&C" : ""}
                          style={{
                            backgroundColor: !termsAgreed ? 'lightblue' : 'blue',
                            color: !termsAgreed ? 'gray' : 'white',
                            cursor: !termsAgreed ? 'not-allowed' : 'pointer',
                            border: '1px solid lightgray',
                            padding: '10px 20px',
                            borderRadius: '5px',
                          }}
                        >
                          CONTINUE{" "}
                        </button>
                      </div>

                    </div>

                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="inner-left">
                  <img src={image.bigcar} alt="" className="car-imgg" />
                  <img src={image.dotscircle} alt="" className="rotate-dots" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={openTermsModal}
          onClose={handleCloseModal}
          aria-labelledby="terms-modal"
          aria-describedby="terms-modal-description"
        >
          <div className="terms-modal" style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            border: '1px solid gray',
            borderRadius: '5px',
            maxHeight: '400px',
            overflowY: 'auto'
          }}>
            <div id="terms-modal-description" dangerouslySetInnerHTML={{ __html: policies }} />
            <hr />
            <div className="modal-footer">
              <button className="btn btn-primary" onClick={handleAcceptTerms}>
                Accept
              </button>
            </div>
          </div>
        </Modal>
      </div>

      <Modal open={openModal2} onClose={handleCloseModal2}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // width: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: 'flex',
            width: { xs: '90%', lg: 600 }, // Responsive width
            flexWrap: 'wrap',
            height: '450px',
            overflowY: 'auto',
          }}
        >

          {/* Billing Details Section */}
          <Box sx={{ flex: 1, paddingRight: { xs: 0, lg: 2 }, borderRight: '1px solid #ccc', paddingBottom: 2 }}>
            <h4 style={{ marginBottom: '16px', fontWeight: 'bold' }}>Billing Details</h4>
            <hr style={{ marginBottom: '16px' }} />
            <Typography variant="body1" sx={{ marginBottom: '8px', lineHeight: 1.5 }}>
              <strong style={{ color: 'cornflowerblue' }}>Name:</strong> <span style={{ color: 'gray' }}>{customerId ? `${firstName} ${lastName}` : `${fname} ${lname}`}</span>
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '8px', lineHeight: 1.5 }}>
              <strong style={{ color: 'cornflowerblue' }}>Email:</strong> <span style={{ color: 'gray' }}>{customerId ? `${email}` : `${email2}`}</span>
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '8px', lineHeight: 1.5 }}>
              <strong style={{ color: 'cornflowerblue' }}>Phone:</strong> <span style={{ color: 'gray' }}>{customerId ? `${phone}` : `${mobileNumber2}`}</span>
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '8px', lineHeight: 1.5 }}>
              <strong style={{ color: 'cornflowerblue' }}>License Plate:</strong> <span style={{ color: 'gray' }}>{licensePlateNumber}</span>
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '8px', lineHeight: 1.5 }}>
              <strong style={{ color: 'cornflowerblue' }}>Check-in:</strong> <span style={{ color: 'gray' }}>{moment(startDate).format('DD-MM-YYYY HH:mm')}</span>
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '8px', lineHeight: 1.5 }}>
              <strong style={{ color: 'cornflowerblue' }}>Check-out:</strong> <span style={{ color: 'gray' }}>{moment(endDate).format('DD-MM-YYYY HH:mm')}</span>
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '8px', lineHeight: 1.5 }}>
              <strong style={{ color: 'cornflowerblue' }}>Amount to pay now:</strong> <span style={{ color: 'gray' }}>$ {customerId ? (totalAmount / 100).toFixed(2) : totalPaidAmount} </span>
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '8px', lineHeight: 1.5 }}>
              <strong style={{ color: 'cornflowerblue' }}>Amount to pay On-Site:</strong> <span style={{ color: 'gray' }}>$ {(totalDueAmount)}</span>
            </Typography>
            {/* <Typography variant="body1" sx={{ marginBottom: '8px', lineHeight: 1.5 }}>
              <strong style={{ color: 'cornflowerblue' }}>Total Amount:</strong> <span style={{ color: 'gray' }}>${(totalAmount / 100).toFixed(2)}</span>
            </Typography> */}
          </Box>

          {/* Payment Form Section */}
          <Box sx={{ flex: 1, paddingLeft: { xs: 0, lg: 2 } }}>

            {clientsecret &&
              <Elements stripe={stripepromise} options={clientsecret}>
                <h4>Payment</h4>
                <hr></hr>
                <PaymentForm payload={paymentFormPayload} clientsecret={clientsecret} stripeorderid={stripeorderid} totalAmount={totalAmount} onCancel={handleCancel} />
              </Elements>
            }
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default BookingCreate;