import { useEffect, useState } from "react";
import { loadStripe } from '@stripe/stripe-js';
import { PaymentElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { Grid, Box, Modal, TextField, Typography, Button, FormControl, InputLabel, Select, MenuItem, IconButton, ListItemIcon, ListItemText, Input, FormControlLabel, Radio, RadioGroup, FormLabel } from '@mui/material';
import Apiconnection from "../../utils/Apiconnection";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router-dom";


const PaymentForm = ({ payload, clientsecret, stripeorderid, totalAmount, onCancel }) => {
    console.log('payload', payload)
    // console.log('clientsecret', clientsecret)

    const userId = JSON.parse(localStorage.getItem('userInfo'));

    const { email, password, customerId, firstName, lastName, phone, licensePlateNumber, startDate, endDate } = payload;
    const [customerName, setCustomerName] = useState(payload?.firstName + ' ' + payload?.lastName);
    const [Firstname, setFirstname] = useState(payload?.firstName);
    const [Lastname, setLastname] = useState(payload?.lastName);
    const [vendorId, setVendorId] = useState(payload?.vendorId);
    // const [email2, setEmail2] = useState(payload?.email);
    const [vechiletypeId, setvechiletypeId] = useState(payload?.vehicleTypeId);
    const [ReservationId, setReservationId] = useState(payload?.reservationId);
    const [cnfReservationId, setcnfReservationId] = useState(payload?.reservationId);
    const [bookingPlatform, setbookingPlatform] = useState(payload?.bookingPlatform);
    const [logInType, setlogInType] = useState(payload?.logInType);
    const [slotId, setslotId] = useState(payload?.slotId);
    const [dialCode, setdialCode] = useState(payload?.dialCode);
    const [fromDate, setfromDate] = useState(payload?.fromDate);
    const [selectedCheckInTime, setselectedCheckInTime] = useState(payload?.selectedCheckInTime);
    const [toDate, settoDate] = useState(payload?.toDate);
    const [selectedCheckOutTime, setselectedCheckOutTime] = useState(payload?.selectedCheckOutTime);
    const [passengerCount, setpassengerCount] = useState(payload?.passengerCount);
    // const [customerId, setcustomerId] = useState(payload?.customerId);
    const [actualvehicleTypeId, setactualvehicleTypeId] = useState(payload?.actualvehicleTypeId);
    const [actualFromDate, setactualFromDate] = useState(payload?.actualFromDate);
    const [actualCheckInTime, setactualCheckInTime] = useState(payload?.actualCheckInTime);
    const [actualToDate, setactualToDate] = useState(payload?.actualToDate);
    const [actualCheckOutTime, setactualCheckOutTime] = useState(payload?.actualCheckOutTime);
    const [dailyParkingRate, setdailyParkingRate] = useState(payload?.dailyParkingRate);
    const [onSiteChargeForVendor, setonSiteChargeForVendor] = useState(payload?.onSiteChargeForVendor);

    const [mobileNumber, setMobileNumber] = useState(payload?.phone);
    const [plateNumber, setPlateNumber] = useState(payload?.plateNumber);
    const [confirmPlateNumber, setConfirmPlateNumber] = useState(payload?.plateNumber);
    const [numPassengers, setNumPassengers] = useState(payload?.passengerCount);
    const [carType, setCarType] = useState(payload?.carType || '');
    const [slotName, setSlotName] = useState(payload?.slotName || '');
    const [startDateTime, setStartDateTime] = useState(payload?.fromDate + 'T' + payload?.selectedCheckInTime);
    const [endDateTime, setEndDateTime] = useState(payload?.toDate + 'T' + payload?.selectedCheckOutTime);
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
    const [invoiceCreate, setInvoiceCreate] = useState({});
    const [bookingRequestDetail, setBookingRequestDetail] = useState({});
    const [vehicleTypeName, setVehicleTypeName] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [stripePkey, setstripePkey] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    // const [clientsecret, setclientsecret] = useState(null);
    const [paymentres, setpaymentres] = useState('');
    const [stripePromise, setStripePromise] = useState(null);
    const [options, setOptions] = useState('pi_3QQMswCvBhAhbwyk0pPWwQmI_secret_55RFUiazZCR7YLbaYV0eSeQP6');
    const [openModal, setOpenModal] = useState(false);
    const [email2, setEmail2] = useState(payload?.email);
    const [fname, setfname] = useState(payload?.firstname);
    const [lname, setlname] = useState(payload?.lastname);


    // const [startDate, setstartDate] = useState(null)
    // const [endDate, setendDate] = useState(null)
    // const [licensePlateNumber, setLicensePlateNumber] = useState("");

    const [submitDisabled, setSubmitDisabled] = useState(true);
    // const { customerId } = location.state || {};
    const [mobileNumber2, setMobileNumber2] = useState(payload?.password);

    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        if (!stripe) {
            return
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        )

        console.log('clientSecret', clientSecret)

        if (!clientSecret) {
            return
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    console.log("payment success")

                    break;
                case "processing":
                    console.log("Your payment is processing")
                    break;

                case "requires_payment_method":
                    console.log("Your payment was not successfully, please try again")

                    break;

                default:

                    console.log("something went wrong")
                    break;
            }
        })


    }, [stripe, clientsecret]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        let newErrors = {};



        if (!stripe || !elements) {
            toast.error('Stripe.js has not loaded yet.');
            return;
        }

        // Confirm the payment
        const result = await stripe.confirmPayment({
            elements,
            clientsecret,
            confirmParams: {
                return_url: "http://localhost:3001/park-prime/park-prime-frontend-admin/?#/booking-details",
            },
            redirect: "if_required",
        });

        console.log('Payment confirmation result:', result);

        const verifyPaymentPayload = {
            stripeOrderId: stripeorderid,
            amount: totalAmount,
            currency: "USD",
            userId: userId?.id,
            firstName: customerId ? firstName : fname,
            lastName: customerId ? lastName : lname,
        };

        const verifyPaymentResponse = await Apiconnection.post('verify-payment-via-stripe', verifyPaymentPayload);
        const transactionId = verifyPaymentResponse.data.data.id;
        const transactioncreatedId = verifyPaymentResponse.data.data.transactionCreatedId;

        let payload = {
            bookingPlatform: "customerPanel",
            logInType: customerId ? "Customer" : "Guest",
            customerId: customerId ? customerId : "",
            vehicleTypeId: "72",
            dialCode: '+1',
            slotId: slotId,
            firstName: customerId ? firstName : fname,
            lastName: customerId ? lastName : lname,
            email: customerId ? email : email2,
            phone: customerId ? phone : mobileNumber2,
            plateNumber: licensePlateNumber,
            fromDate: moment(fromDate).format('YYYY-MM-DD'),
            selectedCheckInTime: moment(startDateTime).format(('HH:mm:ss')),
            toDate: moment(toDate).format('YYYY-MM-DD'),
            selectedCheckOutTime: moment(endDateTime).format(('HH:mm:ss')),
            passengerCount: "4",
            transactionHistoryId: transactionId,
            transactionCreatedId: transactioncreatedId
        };

        try {
            const response = await Apiconnection.post('booking-request-create', payload);
            if (response?.data?.status) {
                const bookingRequestId = response.data.data.id;
                // Extract values from the booking response
                const bookingRequestcheckinDate = response.data.data.fromDate; // from booking-request-create response
                const bookingRequeststartTime = response.data.data.selectedCheckInTime; // from booking-request-create response
                const bookingRequestcheckoutDate = response.data.data.toDate; // from booking-request-create response
                const bookingRequestendTime = response.data.data.selectedCheckOutTime; // from booking-request-create response

                // Prepare the payload for calculate-booking-charges-before-booking API
                const calculateBookingChargesPayload = {
                    platform: "CustomerPanel",
                    bookingRequestId,
                    vehicleTypeId: "72",
                    passengerCount: "4",
                    checkinDate: bookingRequestcheckinDate, // Use fromDate from booking response
                    startTime: bookingRequeststartTime, // Use selectedCheckInTime from booking response
                    checkoutDate: bookingRequestcheckoutDate, // Use toDate from booking response
                    endTime: bookingRequestendTime, // Use selectedCheckOutTime from booking response
                };
                const calculateBookingChargesResponse = await Apiconnection.post('calculate-booking-charges-before-booking', calculateBookingChargesPayload);
                if (calculateBookingChargesResponse?.data?.status) {
                    toast.success('Booked Successfully!');
                    const bookingDetails = {
                        invoiceCreate: calculateBookingChargesResponse.data.data?.invoiceCreate,
                        bookingRequestDetail: calculateBookingChargesResponse?.data?.data?.bookingRequestDetail,
                        customerid: payload?.customerId
                    };
                    navigate('/booking-details', { state: bookingDetails });
                    console.log("bookingDetails", bookingDetails)
                } else {
                    toast.error('Failed to calculate booking charges!');
                }
            } else {
                toast.error('Failed to create booking!');
            }
        } catch (error) {
            console.error('Error adding vehicle data:', error);
            toast.error('Failed to create booking!');
        }
    };



    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            {/* {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>} */}
            <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button color="primary" onClick={onCancel}>
                    Cancel
                </Button>
                <Button type="submit" color="primary" sx={{ ml: 1 }}>
                    Confirm Payment
                </Button>

            </Box>
            {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
        </form>
    )
}

export default PaymentForm